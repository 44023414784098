<template>
  <b-card
    body-class="p-50"
    class="mb-50 mb-md-1 border-info-2"
  >
    <!-- SECTION - Header card -->
    <div class="d-flex-center flex-column flex-md-row justify-content-md-between mb-50">
      <div class="bg-blue-gradient w-100 mb-25 mb-md-0 d-flex">
        <div
          v-if="!isMobileView"
          class="d-flex-center"
        >
          <b-alert
            show
            variant="primary"
            class=" p-1 mr-1 mb-0"
            style="width: fit-content"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="30"
            />
          </b-alert>
        </div>
        <div>
          <h3 class="text-airline">
            {{ $t('flight.priceDetails') }}
          </h3>
          <span>
            {{ $t('flight.priceDetail') }}
          </span>
        </div>
      </div>
      <!-- ANCHOR SHOW HIDE OPTIONS -->
      <div
        :class="isMobileView ? 'd-flex-center flex-wrap' : ''"
      >
        <b-button
          v-b-modal.modal-customer-service-fee
          size="sm"
          variant="outline-primary"
          :class="isMobileView ? 'px-75' : ''"
        >
          <span class="mr-25 align-middle">Sửa phí dịch vụ</span>
          <feather-icon
            v-if="!isMobileView"
            icon="EditIcon"
            size="12"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover.v-warning.window
          variant="flat-warning"
          class="p-75 rounded-circle mx-25"
          :title="`${showServiceFee ? 'Ẩn' : 'Hiện'} phí dịch vụ`"
          @click="showServiceFee = !showServiceFee"
        >
          <feather-icon
            icon="DollarSignIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover.v-info.window
          variant="flat-info"
          class="p-75 rounded-circle mx-25"
          :title="`${showBenefit ? 'Ẩn' : 'Hiện'} lợi nhuận`"
          @click="showBenefit = !showBenefit"
        >
          <feather-icon
            v-if="showBenefit"
            icon="EyeIcon"
            size="16"
          />
          <feather-icon
            v-else
            icon="EyeOffIcon"
            size="16"
          />
        </b-button>
        <b-button
          v-if="false"
          v-b-tooltip.hover.v-danger.window
          variant="flat-danger"
          class="p-75 rounded-circle mx-25"
          :title="`${showPromotion ? 'Ẩn' : 'Hiện'} chiết khấu vào giá`"
          @click="showPromotion = !showPromotion"
        >
          <feather-icon
            icon="PercentIcon"
            size="16"
          />
        </b-button>
      </div>
    </div>
    <!-- !SECTION -->

    <!-- SECTION - Modal Custom Service Fee -->
    <ModalCustomServiceFee />

    <!-- SECTION - Details Trip -->
    <b-card
      class="border"
      header-class="py-1"
    >
      <template #header>
        <div class="d-flex">
          <b
            class="font-medium-3"
          >
            {{ selectedTrip.map(t => `${t.departure.IATACode}-${t.arrival.IATACode}`).join(', ') }}
          </b>
        </div>
      </template>

      <b-table-lite
        bordered
        responsive
        class="mb-1 rounded"
        thead-class="text-dark font-weight-bolder text-nowrap"
        :fields="showServiceFee ? tripPriceColumns : tripPriceColumns.filter(item => item.key !== 'Service_Charge')"
        :items="priceItems"
      >
        <template
          v-for="column in tripPriceColumns"
          v-slot:[`head(${column.key})`]="data"
        >
          <span
            :key="column.label"
            class="text-dark"
          >
            {{ $t(`flight.${data.label}`) }}
          </span>
        </template>

        <template #cell(Passenger)="data">
          <h5 class="text-dark align-middle text-nowrap">
            {{ $t(`flight.${data.item.paxType}`) }} x{{ data.item.numPaxType }}
          </h5>
        </template>

        <template #cell(Price)="data">
          <div v-if="data.item.discount || (showPromotion && data.item.promotion)">
            <h6
              class="text-secondary text-right text-nowrap"
            >
              <del class="text-danger">{{ formatCurrency(data.item.fare) }}</del>
            </h6>
            <h5
              class="text-dark text-right text-nowrap"
            >
              {{ showPromotion ? formatCurrency(data.item.fare - data.item.discount - data.item.promotion) : formatCurrency(data.item.fare - data.item.discount) }}
            </h5>
          </div>
          <h5
            v-else
            class="text-dark text-right text-nowrap"
          >
            {{ formatCurrency(data.item.fare) }}
          </h5>
        </template>

        <template #cell(Tax)="data">
          <h5 class="text-dark text-right text-nowrap">
            {{ formatCurrency(showServiceFee ? data.item.totalSurcharge : (data.item.totalSurcharge + data.item.serviceFee)) }}
          </h5>
        </template>

        <template #cell(Service_Charge)="data">
          <h5 class="text-dark text-right text-nowrap">
            {{ formatCurrency(data.item.serviceFee) || 0 }}
          </h5>
        </template>

        <template #cell(Promotion)="data">
          <h5 class="text-dark text-right text-nowrap">
            {{ formatCurrency(data.item.promotion) }}
          </h5>
        </template>

        <template #cell(Total)="data">
          <div class="text-right text-nowrap">
            <del
              v-if="data.item.discount || (showPromotion && data.item.promotion)"
              class="text-danger"
            > {{ showPromotion ? formatCurrency(data.item.total + data.item.discount + data.item.promotion) : formatCurrency(data.item.total + data.item.discount) }}</del>
            <h5 class="text-dark">
              {{ showPromotion ? formatCurrency(data.item.total) : formatCurrency(data.item.total + data.item.promotion) }}
            </h5>
          </div>
        </template>
      </b-table-lite>

      <!--Trip price -->
      <div
        class="d-flex flex-wrap flex-md-nowrap"
        :class="isMobileView ? 'flex-column' : 'justify-content-between'"
      >
        <div
          class="flex-grow-1"
          :class="isMobileView ? '' : 'mr-2'"
        >
          <div v-show="showBenefit">
            <div
              class="d-flex-between"
              style="min-width: 250px"
            >
              <strong>Chiết khấu:</strong>
              <strong class="font-medium-1">
                {{ formatCurrency(getTotalPriceTripComputed?.promotions ?? 0) }}
              </strong>
            </div>
            <div
              class="d-flex-between"
              style="min-width: 250px"
            >
              <div>
                <strong>Lợi nhuận:</strong>
                <div>
                  <em>
                    <small>
                      (<span v-if="!showPromotion">Chiết khấu + </span>Phí dịch vụ<span v-if="!selectedTrip[0].fareOptions.hideFee"> - Phí xuất vé</span>)
                    </small>
                  </em>
                </div>
              </div>
              <strong class="font-medium-1">
                {{ formatCurrency(getTotalPriceTripComputed?.benefit ?? 0) }}
              </strong>
            </div>
          </div>
        </div>

        <div
          class=" flex-grow-1"
          :class="isMobileView ? 'mt-1' : 'ml-2'"
        >
          <div class="d-flex-between">
            <div>
              <strong class="font-medium-2">{{ $t('flight.total') }}:</strong>
            </div>
            <strong class="font-medium-2">
              {{ formatCurrency(getTotalPriceTripComputed?.total ?? 0) }}
            </strong>
          </div>
          <div class="text-primary font-small-3 text-right">
            <i>({{ $t('flight.fareText') }})</i>
            <p
              v-if="showBenefit"
              class="mb-0"
            >
              <b>
                <span class="text-danger">*</span>
                <i> {{ $t('flight.noteDiscount') }}</i>
              </b>
            </p>
          </div>
        </div>
      </div>
    </b-card>

    <!-- SECTION - Tổng chi phí -->
    <div
      class="d-flex-center"
    >
      <span class="text-dark font-weight-bolder">
        {{ $t('flight.totalFare') }}:
        <span class="text-warning font-medium-5">
          {{ formatCurrency(getTotalPriceTicket(selectedTrip)) }}
        </span>
      </span>
    </div>
    <!-- !SECTION -->
  </b-card>
</template>

<script>
import {
  BCard, BAlert, BButton, BTableLite, VBTooltip,
} from 'bootstrap-vue'
import {
  computed, ref, toRefs,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

import store from '@/store'

import { formatCurrency } from '@core/utils/filter'

import { combinateFareOptions } from '@flightv2/useBookingHandle'

export default {
  components: {
    BAlert,
    BCard,
    BButton,
    BTableLite,
    ModalCustomServiceFee: () => import('../result/components/ModalCustomServiceFee.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    paxSsrData: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const showBenefit = ref(false)
    const showPromotion = ref(false)
    const showServiceFee = ref(false)
    const tripPriceColumns = [
      { label: 'passenger', key: 'Passenger' },
      { label: 'Price', key: 'Price' },
      { label: 'Tax', key: 'Tax' },
      { label: 'serviceCharge', key: 'Service_Charge' },
      // { label: 'Discount', key: 'Discount' },
      // { label: 'Promotion', key: 'Promotion' },
      { label: 'Total', key: 'Total' },
    ]

    const customFeeServiceDataToModify = computed(() => store.getters['app-flight-v2/getCustomFeeServiceDataToModify'])

    function getTotalPriceTrip(trip) {
      const fare = trip?.fareOptions
      const passengers = trip?.fareOptions?.passenger
      const source = trip?.source
      const domestic = false
      if (!fare || !passengers) {
        return { total: 0, benefit: 0 }
      }
      const {
        hideFee, totalAgencyFee,
      } = fare

      const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](domestic, source))
      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (customFeeServiceDataToModify.value?.adultAmount || customFeeServiceDataToModify.value?.childAmount || customFeeServiceDataToModify.value?.infantAmount) {
        modifiedServiceFee.adultAmount = customFeeServiceDataToModify.value?.adultAmount * fare.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = customFeeServiceDataToModify.value?.childAmount * fare.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = customFeeServiceDataToModify.value?.infantAmount * fare.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? trip?.segments?.length || 1 : 1
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }

      const serviceFeeAdultAfterCustom = modifiedServiceFee.adultAmount !== null ? modifiedServiceFee.adultAmount : fare.serviceFeeAdult
      const serviceFeeChildAfterCustom = modifiedServiceFee.childAmount !== null ? modifiedServiceFee.childAmount : fare.serviceFeeChild
      const serviceFeeInfantAfterCustom = modifiedServiceFee.infantAmount !== null ? modifiedServiceFee.infantAmount : fare.serviceFeeInfant

      const adultPrice = (fare.totalAdultModified + serviceFeeAdultAfterCustom - fare.serviceFeeAdult) * passengers.adult
      const childPrice = (fare.totalChildModified + serviceFeeChildAfterCustom - fare.serviceFeeChild) * passengers.child
      const infantPrice = (fare.totalInfantModified + serviceFeeInfantAfterCustom - fare.serviceFeeInfant) * passengers.infant

      const totalPromotions = fare.promotionAdult * passengers.adult + fare.promotionChild * passengers.child + fare.promotionInfant * passengers.infant
      const benefit = (showPromotion.value
        ? (serviceFeeAdultAfterCustom * passengers.adult
          + serviceFeeChildAfterCustom * passengers.child
          + serviceFeeInfantAfterCustom * passengers.infant)
        : ((fare.promotionAdult + serviceFeeAdultAfterCustom) * passengers.adult
          + (fare.promotionChild + serviceFeeChildAfterCustom) * passengers.child
          + (fare.promotionInfant + serviceFeeInfantAfterCustom) * passengers.infant)) - (!hideFee ? totalAgencyFee : 0)
      const total = adultPrice + childPrice + infantPrice + (showPromotion.value ? 0 : totalPromotions)
      return { total, benefit, promotions: totalPromotions }
    }

    const getTotalPriceTicket = trips => {
      const passengers = trips[0]?.fareOptions?.passenger
      if (!trips || !passengers) {
        return 0
      }

      let totalPrice = 0
      trips.forEach(trip => {
        totalPrice += getTotalPriceTrip(trip).total
      })
      // totalPrice = getTotalPriceTrip(fareOptionsArray, passengers).total

      return totalPrice
    }

    const resolvePriceData = trip => {
      const paxTypes = ['Adult', 'Child', 'Infant']
      const fareOptions = combinateFareOptions(trip.map(t => t.fareOptions))
      const source = trip[0]?.source
      const domestic = trip[0]?.domestic ?? false // FIXME phai check theo tung chang
      const getFeeServiceInSelectedProfile = computed(() => store.getters['app-flight-v2/getFeeServiceInSelectedProfile'](domestic, source))
      const modifiedServiceFee = {
        adultAmount: null,
        childAmount: null,
        infantAmount: null,
      }
      // Nếu có custom pdv
      if (customFeeServiceDataToModify.value?.adultAmount || customFeeServiceDataToModify.value?.childAmount || customFeeServiceDataToModify.value?.infantAmount) {
        modifiedServiceFee.adultAmount = customFeeServiceDataToModify.value?.adultAmount * fareOptions.amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = customFeeServiceDataToModify.value?.childAmount * fareOptions.amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = customFeeServiceDataToModify.value?.infantAmount * fareOptions.amountSegmentToMultipleFeeService
      } else if (getFeeServiceInSelectedProfile.value) {
      // nếu chọn profile pdv
        const isSegmentFeeType = getFeeServiceInSelectedProfile.value?.feeType === 'FLIGHT_SEGMENT'
        const amountSegmentToMultipleFeeService = isSegmentFeeType ? fareOptions.amountSegmentToMultipleFeeService : trip.length
        modifiedServiceFee.adultAmount = getFeeServiceInSelectedProfile.value?.adultAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.childAmount = getFeeServiceInSelectedProfile.value?.childAmount * amountSegmentToMultipleFeeService
        modifiedServiceFee.infantAmount = getFeeServiceInSelectedProfile.value?.infantAmount * amountSegmentToMultipleFeeService
      }
      const { hideFee, agencyFee } = fareOptions
      return paxTypes.map(paxType => {
        const numPaxType = fareOptions.passenger[paxType?.toLowerCase()]
        // NOTE Code hiển thị giá đã nhân với numPaxType
        // const fare = fareOptions[`fare${paxType}`] * numPaxType
        // const totalSurcharge = (fareOptions[`surcharge${paxType}`] + fareOptions[`tax${paxType}`] + (paxType !== 'Infant' && hideFee ? agencyFee : 0)) * numPaxType
        // const discount = fareOptions[`discount${paxType}`] * numPaxType
        // const total = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? (modifiedServiceFee[`${paxType.toLowerCase()}Amount`] - fareOptions[`serviceFee${paxType}`] + fareOptions[`total${paxType}Modified`]) * numPaxType : fareOptions[`total${paxType}Modified`] * numPaxType
        // const promotion = fareOptions[`promotion${paxType}`] * numPaxType
        // const serviceFee = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? modifiedServiceFee[`${paxType.toLowerCase()}Amount`] * numPaxType : fareOptions[`serviceFee${paxType}`] * numPaxType

        // NOTE: Code giá từng pax
        const fare = fareOptions[`fare${paxType}`]
        const totalSurcharge = (fareOptions[`surcharge${paxType}`] + fareOptions[`tax${paxType}`] + (paxType !== 'Infant' && hideFee ? agencyFee : 0))
        const discount = fareOptions[`discount${paxType}`] || 0
        const total = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? (modifiedServiceFee[`${paxType.toLowerCase()}Amount`] - fareOptions[`serviceFee${paxType}`] + fareOptions[`total${paxType}Modified`]) : fareOptions[`total${paxType}Modified`]
        const promotion = fareOptions[`promotion${paxType}`]
        const serviceFee = modifiedServiceFee[`${paxType.toLowerCase()}Amount`] !== null ? modifiedServiceFee[`${paxType.toLowerCase()}Amount`] : fareOptions[`serviceFee${paxType}`]

        const result = {
          paxType,
          fare,
          totalSurcharge,
          discount,
          total,
          numPaxType,
          promotion,
          serviceFee,
        }
        return result
      })
        .filter(item => item.numPaxType)
    }

    const { selectedTrip } = toRefs(props)
    const priceItems = computed(() => resolvePriceData(selectedTrip.value))

    const getTotalPriceTripComputed = computed(() => {
      const rs = combinateFareOptions(selectedTrip.value.map(trip => getTotalPriceTrip(trip)))
      return rs
    })
    return {
      priceItems,
      tripPriceColumns,
      formatCurrency,
      getTotalPriceTicket,
      showBenefit,
      showServiceFee,
      showPromotion,
      getTotalPriceTripComputed,
    }
  },
}
</script>
